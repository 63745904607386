<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <div class="login-page">
            <div class="text-center">
              <v-avatar rounded width="250">
                <img alt="Logo" src="/logos/logo_inline.svg" />
              </v-avatar>
            </div>
            <h1 class="text-center">Login</h1>
            <v-text-field
              id="email"
              v-model="email"
              prepend-icon="fi-rr-user"
              name="email"
              label="Email"
              type="text"
            >
            </v-text-field>
            <v-text-field
              id="password"
              v-model="password"
              prepend-icon="fi-rr-lock"
              name="password"
              label="Password"
              type="password"
            >
            </v-text-field>
            <div class="text-right">
              <v-btn @click="login" :loading="isLoading">Login</v-btn>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { projectAuth, signInWithEmailAndPassword } from '@/firebase/config'

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      authErrors: {
        'auth/invalid-email': 'Por favor insira um endereço de e-mail válido.',
        'auth/argument-error':
          'Por favor insira um endereço de e-mail e senha válidos.',
        'auth/email-already-in-use': 'Este endereço de e-mail já está em uso.',
        'auth/weak-password': 'Sua senha precisa ter pelo menos 6 caracteres.',
        'auth/wrong-password':
          'A senha está inválida ou o usuário não possui senha',
      },
    }
  },
  methods: {
    async login() {
      this.isLoading = true
      // @TODO validar email e senha
      return signInWithEmailAndPassword(projectAuth, this.email, this.password)
        .then(
          (userCredential) => {
            console.log('userCredential', userCredential)
            // this.$store.dispatch('currentUser/loadCurrentUser', userCredential)
            this.$router.push('/')
          },
          (error) => {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            if (errorCode === 'auth/wrong-password') {
              alert('Wrong password.')
            } else {
              alert(errorMessage)
            }
            console.log(error)
            alert('Não foi possível realizar o login. ' + error.message)
          }
        )
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
